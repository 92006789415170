import React from "react"
import PropTypes from "prop-types"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Button, Block } from "components/atoms"
import { Alert } from "components/molecules"

import { displayError } from "../../helpers/functions"

// ICONS
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

class EmailSignIn extends React.Component {
  static defaultProps = {}

  static propTypes = {}

  constructor() {
    super()
    this.state = {
      loading: false,
      errorCode: null,
      email: null,
      password: null,
      showPassword: false,
    }
    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleSubmit = e => {
    e.preventDefault()
    const { email, password } = this.state
    const { onSubmit } = this.props
    this.setState({ loading: true })
    return onSubmit(email, password)
      .then(() => this.setState({ loading: false }))
      .catch(error => this.setState({ errorCode: error.code, loading: false }))
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value })
  }

  render() {
    const { loading, errorCode, email, password, showPassword } = this.state
    return (
      <Block
        css={{
          ".MuiButton-containedPrimary": {
            color: "white",
          },
        }}
      >
        <form onSubmit={this.handleSubmit}>
          <FormControl margin="dense" required fullWidth>
            <InputLabel>Email</InputLabel>
            <Input
              type={"text"}
              value={email}
              placeholder={"example@gmail.com"}
              onChange={this.handleChange("email")}
            />
          </FormControl>
          <FormControl margin="dense" required fullWidth>
            <InputLabel htmlFor="adornment-newPassword">Password</InputLabel>
            <Input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={this.handleChange("password")}
              inputProps={{ minLength: 6 }}
              placeholder={"โปรดกรอก 6 ตัวอักษรขึ้นไป"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({ showPassword: !showPassword })
                    }
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button.Contain type="submit" fullWidth mt={20} rounded>
            Login
          </Button.Contain>
        </form>
        <Alert.Processing
          open={loading}
          title={"โปรดรอสักครู่"}
          description={"กำลังเข้าสู่ระบบจ้า..."}
        />
        <Alert
          open={!!errorCode}
          status={"danger"}
          title={displayError(errorCode)}
          onClose={() => this.setState({ errorCode: null })}
        />
      </Block>
    )
  }
}

const StyledEmailSignIn = EmailSignIn

StyledEmailSignIn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default StyledEmailSignIn
