import React from "react"
import {
  Block,
  Text,
  Image,
  GatsbyImage,
  Loader,
  GatsbyImageBackground,
} from "components/atoms"
import { withTheme } from "hocs"
import { Location } from "@reach/router"
import EmailSignIn from "components/forms/EmailSignIn"
import EmailSignUp from "components/forms/EmailSignUp"
import { navigate } from "gatsby"
import { Header } from "components/molecules"
import { Footer } from "components/organisms"

import firebase from "firebases/config"
import CloudApi from "api/cloudApi"

// REDUX
import { getProfile } from "modules/auth/actions"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

//HElPERS
import { getLineURL } from "helpers/constants"
import { decryptQRCode } from "helpers/functions"
import qs from "qs"

// Images
import FacebookIcon from "assets/images/facebook-logo.png"
import LineIcon from "assets/images/line-icon.png"

// Theme
import { withTheme as withEmotion } from "emotion-theming"

// Lodash
import get from "lodash/get"

const provider = new firebase.auth.FacebookAuthProvider()
provider.addScope("user_birthday")
provider.addScope("public_profile")
provider.addScope("email")
provider.addScope("user_gender")

class UserLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // openEmailForm: false,
      // openSignUpForm: false,
      formState: "login",
      showLoading: false,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (query.register) {
      this.setState({ formState: "signUpEmail" })
    }
    const formState = get(location, "state.formState")
    if (formState) this.setState({ formState })
    this.setState({ showLoading: true })
    firebase
      .auth()
      .getRedirectResult()
      .then(data => {
        if (data.user && firebase.auth().currentUser) {
          return CloudApi.loginFacebook(data).then(() =>
            navigate(`/MyAccount${location.search}`),
          )
        } else {
          this.setState({ showLoading: false })
        }
      })
  }

  renderForm = () => {
    const { formState } = this.state
    const { getProfile, location, theme } = this.props
    const titleFontSize = get(theme, "loginPage.titleFontSize")
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (formState === "signInEmail")
      return (
        <Block width={[200, 420]} pl={[20, 0]}>
          <EmailSignIn
            onSubmit={
              (email, password) => {
                return firebase
                  .auth()
                  .signInWithEmailAndPassword(email, password)
                  .then(() => {
                    getProfile().then(() => {
                      return navigate(`/MyAccount${location.search}`)
                    })
                  })
              }
              // cannot pass auth.signInWithEmailAndPassword
              // must create new function{}
            }
            onExit={() => this.setState({ openSignInForm: false })}
          />
        </Block>
      )
    if (formState === "signUpEmail")
      return (
        <Block width={["80vw", 420]} mb={[40, 80]}>
          <EmailSignUp
            onSubmit={this.onSignUp}
            onExit={() => this.setState({ openSignUpForm: false })}
            onBack={() => this.setState({ formState: null })}
          />
        </Block>
      )
    return (
      <Block pl={[20, 0]} pr={[20, 0]}>
        <Block
          height={49}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text textAlign={"center"}>
            <h2>
              Sign In
            </h2>
          </Text>
        </Block>
        <Block
          background={"#1777f3"}
          borderRadius={30}
          pr={15}
          pl={15}
          p={"8px"}
          textAlign={"center"}
          position={"relative"}
          mt={30}
          css={{ cursor: "pointer" }}
          onClick={() => {
            firebase.auth().signInWithRedirect(provider)
          }}
        >
          <Image
            src={FacebookIcon}
            width={[15, 22]}
            height={[15, 22]}
            position={"absolute"}
            top={["14px", "20%"]}
            left={20}
          />
          <Text fontWeight={"bold"} color={"white"}>
            Sign in with Facebook
          </Text>
        </Block>

        <Block
          background={"#1bb71f"}
          borderRadius={30}
          pr={15}
          pl={15}
          p={"8px"}
          textAlign={"center"}
          position={"relative"}
          mt={20}
          css={{ cursor: "pointer" }}
          href={getLineURL(decryptQRCode(query.path))}
          onClick={() =>
            (window.location = `${getLineURL(decryptQRCode(query.path))}`)
          }
        >
          <Image
            src={LineIcon}
            width={[15, 22]}
            height={[15, 22]}
            position={"absolute"}
            top={["14px", "20%"]}
            left={20}
          />
          <Text fontWeight={"bold"} color={"white"}>
            Sign in with LINE
          </Text>
        </Block>

        <Block
          borderTop={"1px solid #b4b4b4"}
          mt={45}
          position={"relative"}
          css={{
            ".MuiTypography-body1": {
              transform: "translate(-50%, 0)",
            },
          }}
        >
          <Text
            position={"absolute"}
            top={-27}
            background={"white"}
            p={"10px 15px 10px 15px"}
            borderRadius={"50%"}
            left={"50%"}
            transform={"translate(-50%, 0)"}
            color={"lightGrey"}
            fontWeight={"bold"}
          >
            OR
          </Text>
        </Block>
        <Block mt={22}>
          <Text color={"#4b4b4b"}>With Email and Password</Text>
          <EmailSignIn
            onSubmit={(email, password) => {
              return firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(() => {
                  return navigate(`/MyAccount${location.search}`)
                })
            }}
          />
        </Block>
        <Block
          textAlign={"right"}
          my={"20px"}
          css={{
            ".MuiTypography-body1": {
              cursor: "pointer",
            },
          }}
        >
          <Text onClick={() => this.setState({ formState: "signUpEmail" })}>
            Sign Up
          </Text>
        </Block>
      </Block>
    )
  }

  onSignUp = (email, password) => {
    const { location } = this.props
    return CloudApi.register({ email, password }).then(() =>
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => navigate(`/MyAccount${location.search}`)),
    )
  }

  render() {
    const { logo, loginBackground } = this.props
    const { showLoading } = this.state

    return (
      <Block>
        <Header />
        <Location>
          {() => {
            return (
              <GatsbyImageBackground
                background={
                  loginBackground && loginBackground.childImageSharp.fluid
                }
              >
                <Block
                  position={"relative"}
                  display={"flex"}
                  flexDirection={"column"}
                  backgroundSize={"cover"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  backgroundPosition={"center"}
                  py={[15, 25]}
                >
                  <GatsbyImage
                    image={logo.childImageSharp.fluid}
                    width={[105, 150]}
                    height={[75, 120]}
                  />
                  <Block mt={[10, 25]}>
                    <Block>{this.renderForm()}</Block>
                  </Block>
                </Block>
              </GatsbyImageBackground>
            )
          }}
        </Location>
        {showLoading && <Loader />}
        <Footer />
      </Block>
    )
  }
}

export default connect(
  null,
  dispatch =>
    bindActionCreators(
      {
        getProfile,
      },
      dispatch,
    ),
)(withTheme()(withEmotion(UserLogin)))
